<template lang="html">
  <convenia-modal
    :class="['login-modal', {
      '-no-header': !!feedback,
      '-bpo': appType === 'bpo'
    }]"
    is-opened
    scroll-el="login-container"
    :no-header="!!feedback"
    :title="title"
    :title-props="{ center: true, size: 2 }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <login-feedback v-if="feedback" v-on="$listeners" />
    <login-form
      v-else
      :content="content"
      v-on="$listeners"
      v-bind="$attrs"
    />
  </convenia-modal>
</template>

<script>
import ConveniaModal from '@components/common/Modal'
import LoginForm from '@components/login/Form'
import LoginFeedback from '@components/login/Feedback'

import { mapGetters } from 'vuex'
import * as types from '@store/types'

export default {
  components: {
    ConveniaModal,
    LoginFeedback,
    LoginForm
  },

  props: {
    appType: {
      type: String,
      validator: appType => {
        const types = ['rh', 'bpo']
        return types.includes(appType)
      }
    }
  },

  computed: {
    ...mapGetters({
      content: types.LOGIN_CONTENT,
      feedback: types.LOGIN_FEEDBACK,
      LoginTexts: types.SETTINGS_TEXTS
    }),

    title () {
      return this.LoginTexts[this.content].title
    }
  }
}
</script>

<style lang="scss">
.login-modal {
  & > .modal > .header > .title > .text {
    color: $base-text-color;
    font-weight: bold;
  }

  & > .modal > .wrapper-content > .content {
    display: flex;
    justify-content: center;

    & > .login-feedback { max-width: 360px }

    & > .login-form {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      max-width: 320px;

      & > .title { @include hide }
      & > .info {
        font-size: 16px;
        letter-spacing: $shorter-letter-spacing;
        line-height: 22px;
        text-align: center;
        max-width: 240px;
        width: 100%;
        margin: 0 auto;
      }

      & > .form {
        margin-top: 30px;

        & > .actions {
          flex-direction: column;
          align-items: center;
          margin: 30px 0;

          & > .hint { margin: 0 auto; }

          & > .hint > .link {
            border-bottom: 1px dashed;
            font-weight: bold;
            color: $base-text-color;
            letter-spacing: $short-letter-spacing;
            line-height: 22px;
          }

          & > .action {
            width: 100%;
            max-width: 100%;
            margin-top: 43px;
          }
          & > .recaptcha-warning { margin-top: 20px }
        }
      }
    }
  }

  @include mobile(true) {
    & > .modal > .wrapper-content > .content  > .login-form > .recaptcha-warning {
      max-width: 320px;

      & > .text { position: static }
    }
  }

  &.-no-header > .modal > .header { @include hide }

  &.-bpo {
    --color-a: #{map-get($primary-color-map, light)};
    --color-b: #{map-get($primary-color-map, dark)};
  }
}
</style>
